<template>
  <div class="container">
    <SecondNav :activeIndex="3"></SecondNav>
    <div class="thirdMenu">
      <ul class="flex">
        <li :class="activeIndex === index ? 'active' : ''"
            v-for="(item, index) in list"
            :key="index"
            @click="getData(item.id, index)">
          {{item.text}}
        </li>
      </ul>
    </div>
    <div>
      <div>
        dfgdffgdfg
      </div>
    </div>
  </div>
</template>

<script>
import SecondNav from '../about/components/secondNav'

export default {
  name: 'knowledgeBase',
  data() {
    return {
      list: [
        {
          text: '3234423'
        }
      ],
      activeIndex: 0
    }
  },
  created() {
    // 导航栏选中状态定位
    for (let i = 0; i < this.list.length; i++) {
      const item = this.list[i]
      const routPath = this.$route.path
      if (item.link === routPath) {
        this.activeIndex = i
        break
      }
    }
  },
  methods: {
    getData(id, index) {
      this.activeIndex = index
      //to do
    }
  },
  components: {
    SecondNav
  }
}
</script>

<style scoped lang="scss">
.thirdMenu {
  border-bottom: 1px solid #dcdcdc;
  margin-top: 20px;
}
.thirdMenu {
  margin-top: 20px;
  text-align: left;
}
.thirdMenu li {
  padding: 8px 18px;
  text-align: center;
  background-color: #f0f9eb;
  margin-right: 20px;
  cursor: pointer;
  border-radius: 4px;
  display: inline-block;
}
.thirdMenu .active a {
  color: #fff;
}
.thirdMenu a {
  color: #666;
}
.thirdMenu .active {
  background-color: #93b067;
  color: #fff;
}
.thirdMenu div {
  width: 80%;
  border-top: 4px solid #f00;
  margin: 40px auto 30px;
  position: relative;
}
.thirdMenu div h1 {
  position: absolute;
  background-color: #ffffff;
  padding: 0 30px;
  font-size: 28px;
  color: #f00;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
